.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
}

.pagination button {
    width: 40px;
    height: 30px;
    font-family: inherit;
    font-weight: 400;
    font-size: 10px;
    margin: 0 5px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px;
    background: rgb(219, 208, 208);
    color: #131818;
    border-color: black;
}

.pagination button.active {
    font-weight: 900;
    border-color: #101010;
    background: #e63030cc;
    color: #000000;
}